import React from "react"
import Layout from "../components/layout"
import Videos from "../components/videos"
import YouTubeSubscribe from "../components/youtubeSubscribe";
import { css } from "@emotion/core"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

const channelid = "UCrCJmpy47G_bcEYYCZ60NLw";

export default (data) => {
  return (
    <Layout>
      <SEO title="All about technology" />
      <h1>Learn something new</h1>
      <p>
        It is always nice to get you learn something new about <strong>typescript</strong>, <strong>node.js</strong>, <strong>rxjs</strong>, <strong>jest</strong> or ...
      </p>
      <p>
          With <strong>Type With Me</strong>, you will learn how to use tools, learn how to program for the web and rule the world. 
          Upgrade your skills by watching these tips and tricks on this channel!
      </p>
      <p>
          Update your skills on a day to day basis will make you a better person.
          Stay on top of the latest news around technology will help you to enhance your knowledge. 
          If you have some questions feel free to reach out to us.
      </p>
      <YouTubeSubscribe
          // channelName={channelName}
          channelid={channelid}
          theme={"default"}
          layout={"default"}
          count={"default"}
        />
      <h2 css={css`
          padding: 0px;
        `}>Technology</h2>
        <p>Learn more about the following technology topics. We bundled for you some tools, urls to interesting resources together with our videos on these pages.</p>
        {data.data.allMongodbCloudTechnologies.edges.map(({ node }, i) => {
          return <span css={css`
          margin-right: 5px;
        `}><Link key={i}
              to={node.slug}
              css={css`
                text-decoration: none;
                color: inherit;
              `}
            >{node.name}</Link></span>
        })}
      
      <h2 css={css`
          margin-bottom: 0px; padding: 0px;
        `}>Latest videos</h2>
          <Videos videoData={data.data.allChannelvideos}></Videos>
    </Layout>
  )
} 

export const query = graphql`
  query {
    allMongodbCloudTechnologies {
      edges {
        node {
          name
          slug
        }
      }
    }
    allChannelvideos(limit: 3) {
      nodes {
        snippet {
          description
          title
          thumbnails {
            medium {
              url
              height
              width
            }
          }
        }
        alternative_id {
          videoId
        }
      }
    }
  }
`
